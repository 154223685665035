<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-card title="Dokümanı Düzenle">
        <document-form />
      </b-card>
      <save-button-line :action-methods="saveData" />
    </validation-observer>
  </div>

</template>

<script>

import { BCard } from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import SaveButtonLine from '@/views/Damage_tracking/elements/SaveButtonLine.vue'
import DocumentForm from '@/views/Damage_tracking/document/components/DocumentForm.vue'

export default {
  name: 'EditDocument',
  components: {
    DocumentForm,
    ValidationObserver,
    SaveButtonLine,
    BCard,
  },
  computed: {
    dataItem() {
      return this.$store.getters['damageTrackingDocument/getData']
    },
    saveStatus() {
      return this.$store.getters['damageTrackingDocument/saveDataStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
        this.$router.push(`/damage-tracking/view/${this.dataItem.id_com_damage_tracking}`)
        this.$store.commit('damageTrackingDocument/SET_DATA_RESET')
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.dataItem.submitStatus = false
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      return this.$store.dispatch('damageTrackingDocument/dataItem', this.$route.params.id)
    },
    saveData() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.dataItem.submitStatus = true
          this.$store.dispatch('damageTrackingDocument/saveData', this.dataItem)
        }
      })
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
  },

}
</script>
